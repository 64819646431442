:root {
  --primary-text: rgba(255, 255, 255, 0.8);
  --secondary-text: rgba(255, 255, 255, 0.5);
  --thirdly-text: rgba(255, 255, 255, 0.35);
  --primary-color: #2253f5;
  --border-color: rgba(38, 38, 38, 1);
  --easing-func: cubic-bezier(0.1, 0.6, 0.4, 1);
}

html {
  height: 100%;
  scroll-behavior: smooth;
  overflow: hidden auto;
}

html,
* {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: var(--primary-color);
  font-size: 14px;
  line-height: 20px;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden auto;
}

main {
  max-width: 1172px;
  flex: 1;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 50px 16px 100px 16px;
}

#__next {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

body > * ::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

body > * ::-webkit-scrollbar-track {
  background: transparent;
}

body > * ::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 12px;
  background-clip: padding-box;
}

@media (max-width: 768px) {
  main {
    padding: 40px 8px;
  }
}

.hidden {
  visibility: hidden !important;
}
.hidden > * {
  visibility: hidden !important;
}

h5 {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.2px;
  margin-bottom: 15px;
}

